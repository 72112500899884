import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";

interface CustomCheckboxProps {
  title: string;
  name: string;
  value: boolean;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ title, name, value, onChange }) => {
  return (
    <Box width='100%'>
      <Box display='flex' alignItems='center'>
        <Checkbox name={name} checked={value} onChange={onChange} sx={{
          '&.Mui-checked': {
            color: '#6271FF',
          },
        }} />
        {title && (
          <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
            {title}
          </Typography>
        )}
      </Box>
    </Box>
  )
}


export default CustomCheckbox;
